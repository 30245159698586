(function ($) {

    $(document).on('ready', function () {

        function ctCardPersonInitMargin() {
            $(".card-extra-infos").each(function () {
                let $element = $(this);
                let columnLeft = $element.parents(".column").offset().left;
                let columnRight = $element.parents(".column").outerWidth();
                let containerLeft = $element.parents(".row.card-list").offset().left;
                let containerRight = $element.parents(".row.card-list").outerWidth();
                let marginLeft = containerLeft - columnLeft;
                $element.css("margin-left", marginLeft + "px");
                $element.css("margin-right", (columnRight - containerRight - marginLeft) + "px");
            });
        }

        // init position (by loading and resize)
        ctCardPersonInitMargin();
        $(window).on("resize orientationchange", ctCardPersonInitMargin);

        // Mouse Over/Out
        $(".card-extra-infos").parents(".card.card-person").on("mouseover", function (event) {
            let $elem = $(event.currentTarget);
            $(".card-extra-infos-open", $elem).removeClass("card-extra-infos-open");
            // Change opacity (set all, except the current one)
            $(".row.card-list > .column .card-person").css("opacity", "0.35");
            $elem.css("opacity", "1");
        });
        $(".card-extra-infos").parents(".card.card-person").on("mouseout", function (event) {
            let $elem = $(event.currentTarget);
            $(".card-extra-infos-open", $elem).removeClass("card-extra-infos-open");
            // Change opacity (reset all)
            $(".row.card-list > .column .card-person").css("opacity", "");
        });

        // Click (useful for Mobile)
        $(".card-extra-infos").parents(".card.card-person").on("click", function (event) {
            if (!$(event.target).is('a')) {
                event.preventDefault();
                event.stopPropagation();
            }
            let $elem = $(event.currentTarget);
            let $extraInfos = $(".card-extra-infos-open", $elem);
            if ($extraInfos.length) {
                // Already open: Close it !
                $extraInfos.removeClass("card-extra-infos-open");
            }
            else {
                // Not yet open (this one): Close all existing, and open this one (if exists)
                $(".card-extra-infos-open").removeClass("card-extra-infos-open");
                $extraInfos = $(".card-extra-infos", $elem);
                $extraInfos.addClass("card-extra-infos-open");
            }
        });
        $(document).on("click", function () {
            $(".card-extra-infos-open").removeClass("card-extra-infos-open");
        });

    });

}(jQuery));
