/**
 * Assess whether the curent page has been loaded with a query parameter 'ctthema'.
 * If so, then see if there is a select on the current page with a label containing “Thema”.
 * If so, then set the selectedIndex of that select to match the theme passed in.
 * @since  15.6.2017	mhm
 */
(function($) {

	function getUrlParameter(sParam) {
		// Thanks http://www.jquerybyexample.net/2012/06/get-url-parameters-using-jquery.html
		var sPageURL = decodeURI(window.location.search.substring(1)),
			sURLVariables = sPageURL.split('&'),
			sParameterName,
			i;

		for (i = 0; i < sURLVariables.length; i++) {
			sParameterName = sURLVariables[i].split('=');
			if (sParameterName[0] === sParam) {
				return unescape(sParameterName[1] === undefined ? true : sParameterName[1]);
			}
		}
	}

	var term = getUrlParameter('ctthema');
	var target_label = $('label:contains("Thema")');

	if (term && target_label && target_label.length === 1) {
		var $target_select = $('#' + target_label.attr('for'), target_label.parent());
		if ($target_select && $target_select.length === 1) {
			$('option', $target_select).filter(function() {
				return $(this).text().toLowerCase() === term.toLowerCase();
			}).prop('selected', true);
		}
	}

}(jQuery));
