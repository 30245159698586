(function ($) {

    let livestreams = [];

    var Livestream = function($livesteam, date) {
        this.load = function(singleLoad) {
            let $this = this;
            $.post(projectsAjax.url, { 'action': 'ct_get_livestream', 'id': $this.id }, function (response) {
                $this.setShow(response.data.show);
                $this.setEmbed(response.data.embed, response.data.hash);
                if (!singleLoad) {
                    $this.loadTimeout();
                }
            }).fail(function () {
                console.error("Failed to update livestream status");
            });
        };

        this.loadTimeout = function() {
            setTimeout(this.load.bind(this), 30000);
        };

        this.setEmbed = function(embed, hash) {
            if (hash !== this.$livesteam.attr('data-hash')) {
                this.$embed.html(embed);
                this.$livesteam.attr('data-hash', hash);
            }
        };

        this.setShow = function(show) {
            if (show) {
                this.$livesteam.find('.livesteam-container').removeClass('disabled');
                this.$livesteam.find('.timer-container').addClass('disabled');
            } else {
                this.$livesteam.find('.livesteam-container').addClass('disabled');
                this.$livesteam.find('.timer-container').removeClass('disabled');
            }
        };

        this.showTime = function() {
            let now = new Date();
            let time = this.date.getTime() - now.getTime();
            let days = this.timeHelper(Math.floor(time / 86400000));
            let hours = this.timeHelper(Math.floor((time % 86400000) / 3600000));
            let minutes = this.timeHelper(Math.floor((time % 3600000) / 60000));
            let seconds = this.timeHelper(Math.floor((time % 60000) / 1000));

            if (this.date.getTime() > now.getTime()) {
                this.updateTimePostion('days', days);
                this.updateTimePostion('hours', hours);
                this.updateTimePostion('minutes', minutes);
                this.updateTimePostion('seconds', seconds);
            } else {
                clearInterval(this.timer);
                this.$counter.text('00:00:00');
                this.load(true);
            }
        };

        this.updateTimePostion = function(name, time){
            this.$counter.find('.time-'+name).text(time);
        };

        this.timeHelper = function(number) {
            number = number.toString();
            return (number.length !== 1 ? number : '0' + number);
        };

        this.$livesteam = $livesteam;
        this.$counter = $('[data-countdown]');
        this.$embed = this.$livesteam.find('[data-embed]');
        this.id = this.$livesteam.attr('data-livesteam');
        if (date) {
            this.date = new Date(date);
            this.timer = setInterval(this.showTime.bind(this), 1000);
        }
        this.loadTimeout();
    };

    $('[data-livesteam]').each(function () {
        livestreams.push(new Livestream($(this), $(this).attr('data-date')));
    });

}(jQuery));