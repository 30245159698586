(function($) {

    var repositionMegaDropdown = function(){
        // delay because of transition
        setTimeout(function(){
            if ($('[data-megadropdown-anchor]').length && 
                $('.mainmenu .menu.level0 > li > ul').length && 
                $('[data-megadropdown-anchor]').is(':visible') && 
                $('.mainmenu .menu.level0 > li > ul').is(':visible')) {
                    var newTop = $('[data-megadropdown-anchor]').outerHeight(false);
                    $('.mainmenu .menu.level0 > li > ul').css({ 'top': newTop });
            }
        }, 350);
    };

	// Custom event watchers
    $(window).on('megadropdown.is-open', function(){
		$('html').addClass('megadropdown-is-open');
    });

    $(window).on('megadropdown.is-closed', function(){
		$('html').removeClass('megadropdown-is-open');
    });

    // Mouseover/mouseout main links
	$('.top-bar .mainmenu .menu.level0 > li').on('mouseover.megadropdown', function(){
        if($('> .sub-menu', $(this)).length){
	       $(window).trigger('megadropdown.is-open');
        }
	}).on('mouseout.megadropdown', function(){
        if($('> .sub-menu', $(this)).length){
    	    $(window).trigger('megadropdown.is-closed');
        }
	});

	// Set position of megadropdown on page load / resize
    $(window).on('load.dm resize.dm wpplugin.ajax-search/search-open sticky.zf.stuckto:top sticky.zf.unstuckfrom:top', repositionMegaDropdown);

    // Add megadropdown overlay to page. Hidden until MDD is opened.

	var $mdd_overlay;

    $(window).load(function(){
    	if(!$mdd_overlay){
    		$mdd_overlay = $('<div class="megadropdown-overlay"></div>');
    		$('body').append($mdd_overlay);
    	}
    });

})(jQuery);