(function($) {

	if ($('[data-ct-sticky-scrollup-container]').length !== 1) {
		if (window.console) {
			window.console.log('data-ct-sticky-scrollup-container - too many elements!');
		}
	} else {

		var $container = $('[data-ct-sticky-scrollup-container]');
		var $element = $('[data-ct-sticky-scrollup]', $container);
		var intervalRunning = false;
		var didScroll;
		var scrolltopWas = 0;
		var intervalDelay = 150; // ms between scroll position checks

		/**
		 * Apply the current height of the containing
		 * element as a fixed style= value, so that it
		 * doesn't collapse when you give its child position:fixed
		 */
		var fixContainerHeight = function() {
			$container.height($element.height());
		};

		/**
		 * Scroll tester - has the document moved in the viewport?
		 */
		var hasScrolled = function() {
			var scrolltopIs = $(window).scrollTop();

			if ($element[0].getBoundingClientRect().top + $element.outerHeight() > 0) {
				$container.trigger('ct-sticky-scrollup:inrange');
			} else {
				$container.trigger('ct-sticky-scrollup:outrange');
			}

			if (scrolltopIs < scrolltopWas) {
				$container.trigger('ct-sticky-scrollup:on');
			} else {
				$container.trigger('ct-sticky-scrollup:off');
			}
			scrolltopWas = scrolltopIs;
		};

		/**
		 * Start the repeating test if the screen 
		 * is smaller than desktop resolution
		 */
		var maybeSetInterval = function() {
			if (window.innerWidth < 1280) {
				intervalRunning = setInterval(function() {
					if (didScroll) {
						hasScrolled();
						didScroll = false;
					}
				}, intervalDelay);
			} else {
				window.clearInterval(intervalRunning);
			}
		};

		// Immediately
		fixContainerHeight();

		// Event handlers
		$(window).on('load.stickyScrollup resize.stickyScrollup', fixContainerHeight);
		$(window).on('load.stickyScrollup resize.stickyScrollup', maybeSetInterval);

		$(window).on('scroll.stickyScrollup', function() {
			didScroll = true;
		});

		$container.on('ct-sticky-scrollup:on', function() {
			$element.addClass('is-stuck');
		});

		$container.on('ct-sticky-scrollup:off', function() {
			$element.removeClass('is-stuck');
		});

		$container.on('ct-sticky-scrollup:outrange', function() {
			$element.addClass('is-outside-range');
		});

		$container.on('ct-sticky-scrollup:inrange', function() {
			$element.removeClass('is-outside-range');
		});

	}

}(jQuery));
