/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

/*jshint multistr:true */

(function($) {
    $( '.triangles_set_1' ).append( '\
        <svg class="triangle" style="top: 25%; left: -2%;" xmlns="http://www.w3.org/2000/svg" width="117" height="135" viewBox="0 0 117 135"><g><g transform="rotate(46 58.5 67.5)"><path fill="#1db588" d="M-22.057 44.646l156.11 31.318L18.197 125.38z"/></g></g></svg>\
        <svg class="triangle" style="top: 25%; right: -2%;" xmlns="http://www.w3.org/2000/svg" width="128" height="118" viewBox="0 0 128 118"><g><g transform="rotate(67 64 59)"><path fill="#fff" d="M76.944-18.078l64 111h-128z"/></g></g></svg>\
        <svg class="triangle" style="bottom: 8%; left: -6%;" xmlns="http://www.w3.org/2000/svg" width="128" height="119" viewBox="0 0 128 119"><g><g transform="rotate(67 64 59.5)"><path fill="#fff" d="M76.833-17.374l64 111h-128z"/></g></g></svg>\
        <svg class="triangle" style="bottom: -6%; right: -6%; xmlns="http://www.w3.org/2000/svg" width="196" height="192" viewBox="0 0 196 192"><g><g><path fill="#1db588" d="M.849 191.14l194.203-74L50.927.38z"/></g></g></svg>\
        <svg class="triangle" style="bottom: -30%; left: -10%; xmlns="http://www.w3.org/2000/svg" width="98" height="73" viewBox="0 0 98 73"><g><g transform="rotate(62 49 36.5)"><path fill="#ebebeb" d="M56.621-23.991l33 92h-66z"/></g></g></svg>' );
    
    $( '.triangles_set_2' ).append( '\
        <svg class="triangle" style="top: -75%; left: 0;" xmlns="http://www.w3.org/2000/svg" width="94" height="83" viewBox="0 0 94 83"><g><g transform="rotate(128 47 41.5)"><path fill="#ebebeb" d="M57 8.325l33 92H24z"/></g></g></svg>\
        <svg class="triangle" style="top: -8%; right: 8%;" xmlns="http://www.w3.org/2000/svg" width="128" height="119" viewBox="0 0 128 119"><g><g transform="rotate(67 64 59.5)"><path fill="#fff" d="M76.905-17.947l64 111h-128z"/></g></g></svg>\
        <svg class="triangle" style="top: 24%; left: -8%;" xmlns="http://www.w3.org/2000/svg" width="173" height="208" viewBox="0 0 173 208"><g><g transform="rotate(-75 86.5 104)"><path fill="#1db588" d="M8.04 184.14l194.202-74L58.117-6.62z"/></g></g></svg>\
        <svg class="triangle" style="top: 50%; right: -2%;" xmlns="http://www.w3.org/2000/svg" width="117" height="135" viewBox="0 0 117 135"><g><g transform="rotate(46 58.5 67.5)"><path fill="#1db588" d="M-22.272 44.646l156.11 31.318L17.984 125.38z"/></g></g></svg>' );

    $( window ).scroll( function() {
        $( '.triangle' ).css( { 'transform' : 'rotate(' + window.pageYOffset / 15 + 'deg)' } );
    } );

	// ws form calculator trigger
	$( '.wsf-form' ).on( 'submit', function(e) {
		e.preventDefault();
		let urlObject = $(this).find('.calculator-redirect-url');
		if (urlObject) {
			let url = urlObject.val();
			if (url) {
				window.open(url, '_blank');
			}
		}
	});

    var goThere = function(target){

        var scrollPosition,
            anchorKey = target;

        target = $(target);
        target = target.length ? target : this.hash ? $('[name=' + this.hash.slice(1) + ']') : null;

        // Does a scroll target exist?
        if (target && target.length) {
            // Only prevent default if animation is actually gonna happen
            if (typeof event !== 'undefined') {
                event.preventDefault();
            }

            if ($('body').hasClass('admin-bar')) {
                scrollPosition = target.offset().top - $('#wpadminbar').outerHeight();
            } else {
                scrollPosition = target.offset().top;
            }

            if($('[data-ct-mobilemenu-anchor]').length){
                scrollPosition -= $('[data-ct-mobilemenu-anchor]').outerHeight();
            }

            if (typeof window.history.pushState === 'function' && !target.get(0).hasAttribute('data-scroll-to-position')) {
                var newHref = window.location.href.replace(window.location.hash, '') + anchorKey;
                history.pushState({}, document.title, newHref);
            }

            $('html, body').animate({
                scrollTop: scrollPosition
            }, 1000);
        }
    };

    // Use this variable to set up the common and page specific functions. If you
    // rename this variable, you will also need to rename the namespace below.
    var Sage = {
        // All pages
        'common': {
            init: function() {
                // JavaScript to be fired on all pages
                $(document).foundation();

                // location dropdown for opening times card
                if ($('.js-change-location').length) {
                    $('.js-change-location').on('change', function() {

                        var location = $(this).val();

                        $('.card-location-list > div').removeClass('active');
                        $('.card-location-list > div[data-location-title="' + location + '"]').addClass('active');

                    });
                }

                //add target _blank to publication search results
                if($('.search-results .ct_publications').length){
                    $('.search-results .ct_publications').each(function(){
                        $(this).find('a').attr('target', '_blank');
                    });
                }

                $('.page-section-jobs-section3 .accordion-title').on('click', function() {
                    $('body').addClass('job-selected-for-print');
                });

                $('[data-is-anchor="true"]').on('click.gothere', function(){
                    goThere($(this).attr('href'));
                });

                // If a hash (#anchor) is provided and there is a link on the page for this hash, click it
                if (window.location.hash && window.location.hash.length && $(window.location.hash).length) {
                    $('a').each(function() {
                        if ($(this).attr('href') && $(this).attr('href').length && $(this).attr('href').indexOf(window.location.hash) > -1) {
                            $(this).trigger('click');
                            return;
                        }
                    });

                    goThere(window.location.hash);

                }

                if ($('[data-scroll-to-position]').length === 1) {
                    goThere($('[data-scroll-to-position]'));
                }

            },
            finalize: function() {
                // JavaScript to be fired on all pages, after page specific JS is fired
            }
        },
        // Home page
        'home': {
            init: function() {
                // JavaScript to be fired on the home page
            },
            finalize: function() {
                // JavaScript to be fired on the home page, after the init JS
            }
        },
        // About us page, note the change from about-us to about_us.
        'about_us': {
            init: function() {
                // JavaScript to be fired on the about us page
            }
        }
    };

    // The routing fires all common scripts, followed by the page specific scripts.
    // Add additional events for more control over timing e.g. a finalize event
    var UTIL = {
        fire: function(func, funcname, args) {
            var fire;
            var namespace = Sage;
            funcname = (funcname === undefined) ? 'init' : funcname;
            fire = func !== '';
            fire = fire && namespace[func];
            fire = fire && typeof namespace[func][funcname] === 'function';

            if (fire) {
                namespace[func][funcname](args);
            }
        },
        loadEvents: function() {
            // Fire common init JS
            UTIL.fire('common');

            // Fire page-specific init JS, and then finalize JS
            $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
                UTIL.fire(classnm);
                UTIL.fire(classnm, 'finalize');
            });

            // Fire common finalize JS
            UTIL.fire('common', 'finalize');
        }
    };

    // Load Events
    $(document).ready(UTIL.loadEvents);
})(jQuery);

function setCookie(cname, cvalue, exdays) {
    var d = new Date();
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
    var expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}
