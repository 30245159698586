/**
 * Basis functionality for toggle is in Foundation.
 * The functionality in this file is for cubetech customisations
 * mhm 11.7.2017
 */
(function ($) {

	/**
	 * Click handler for toggle links
	 * Toggle is--active on the link istelf
	 * If the link is for the #mobileMenuOffCanvas then add an appropriate class to the HTML root element
	 */
	$('[data-toggle]').on('click.isactive', function () {
		$(this).blur();
		$(this).toggleClass('is--active');
		if ($(this).data('toggle') === 'mobileMenuOffCanvas') {
			if ($(this).hasClass('is--active')) {
				$('html').addClass('ct-mobilemenu-open');
				$(window).trigger('ct-mobilemenu.open');
			} else {
				$('html').removeClass('ct-mobilemenu-open search-active');
				$(window).trigger('ct-mobilemenu.closed');
				$('body').removeClass('search-typed search-active');
				$('.results-count span').html('');
			}
		}
	});

}(jQuery));
