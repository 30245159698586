(function($) {

	// trigger accordion of following question
	openNextQuestion = function($changedAnswer) {

		var $thisAccordionItem = $changedAnswer.closest('[data-accordion-item]'), // get the accordion of the question which has been answered
			$nextAccordionItem = $thisAccordionItem.next(); // get the following accordion

		if( !$nextAccordionItem.hasClass('is-active') ) { // if the following accordion is not open yet
			$nextAccordionItem.find('[data-ct-accordion-title]').trigger('click'); // open the accordion in order to show the question
		}
	};

	// calculate the result of all checked answers
	calculateResult = function() {

		var result = 0,
			number_of_questions = $('[data-ct-radio-group]').length, // get the total number of questions
			number_of_checked = 0;

		$("input:radio:checked").each(function(){ // for each checked radio button
			if( $(this).val() ) { // if the value is set
				var value = $(this).val();
				result = result + parseInt(value); // add the value to the result
			}

			number_of_checked++; // increase the total number of answered questions
		});

		if( number_of_checked === number_of_questions ) { // if all questions have been answered
			showResult(result); // show the result
		}
	};

	// set classes to animated elements for desktop
	setDesktopClasses = function( $element, result ) {
			var min = $element.data('ct-value-min'), // get the minimum value for this element to be the result
				max = $element.data('ct-value-max'); // get the minimum value for this element to be the result

			if ( result >= min && result <= max ) { // if the result is between max and min
				$element.addClass('is-result'); // set this element as the result
				$element.removeClass('is-second-rate'); // if defined for this element, remove the class indicating that this is not the result
			} else {
				$element.addClass('is-second-rate'); // set the class indication that this element is not the result
				$element.removeClass('is-result'); // if defined for this element, remove the class indication that this is the result
			}
	};

	// set orders for elements on mobile
	setMobileOrders = function( $element, result ) {

			var min = $element.data('ct-value-min'), // get the minimum value for this element to be the result
				max = $element.data('ct-value-max'); // get the minimum value for this element to be the result

			if ( result >= min && result <= max ) { // if the result is between max and min
				$element.removeClass('small-order-4'); // remove the previous order class
				$element.addClass('small-order-1'); // move this element to top
			} else {
				$element.removeClass('small-order-1'); // remove the previous order class
				$element.addClass('small-order-4'); // move this element down
			}

	};

	transformPiesForIE = function(i, el) {
    	var $pieBackground = $(el).find('[data-pie-background]'),
			cx = $pieBackground.attr('cx'),
			cy = $pieBackground.attr('cy'),
			matrix 	= $(el).css('transform'),
	    	values 	= matrix.match(/-?[\d\.]+/g);

	    	if(values) {
		    	var scale = values[0];

			    values[4] = cx-scale*cx;
			    values[5] = cy-scale*cy;

			    var string = values.join(", ");
			    transform = 'matrix(' + string + ')';

				$(el).attr('transform', transform);	
	    	}
	};

	transformTypoForIE = function(i, el) {
		
    		var coordinates = el.getBBox(),
				cx = coordinates.x + coordinates.width/2,
				cy = coordinates.y + coordinates.height/2,
				matrix 	= $(el).css('transform'),
	    		values 	= matrix.match(/-?[\d\.]+/g);

			if(values) {
				scale = values[0];

			    values[4] = cx-scale*cx;
			    values[5] = cy-scale*cy;

			    var string = values.join(", ");
			    transform = 'matrix(' + string + ')';

				$(el).attr('transform', transform);
			}
	};

	// show the result of the test
	showResult = function(result) {

		var $graphicPie = $('[data-ct-graphic-pie]'), // the pie elements of the graphic (desktop)
			$graphicTypo = $('[data-ct-graphic-typo]'), // the typo elements of the graphic (desktop)
			$legendGroup = $('[data-ct-legend-group]'); // the legend elements, containing typo, text and legend (mobile)

		$('[data-ct-result-container]').html(result); // update the result in the result-header
		$('[data-ct-result-header]').removeClass('hide'); // show the result-header

		//animate pies for desktop
		$graphicPie.each(function(i, el) {

			setDesktopClasses( $(this), result );

			if( i<2 ) { //only for the pies on desktop (first 3 of dom)
				setTimeout( function() {
			    	transformPiesForIE(i, el);
				}, 500);
			}
		});

		//animate texts for desktop
		$graphicTypo.each(function(i, el) {
			setDesktopClasses( $(this), result );
			
			setTimeout( function() {
		    	transformTypoForIE(i, el);
			}, 500);
		});

		//set order for legend-groups on mobile
		$legendGroup.each(function() {
			setMobileOrders( $(this), result );
		});

	};

	// start actions everytime a radio button has been changed (clicked)
	$('[data-ct-answer-radio]').on('change', function() {
		$(this).parents('.menu').find('.answer-label').removeClass('checked');
		$(this).parents('.answer-label').addClass('checked');

		calculateResult();
		openNextQuestion($(this));
	});


}(jQuery));
