jQuery(document).ready(function() {

    jQuery('.js-disclaimer-cookie-action').on('click', function(disclaimerButton){

        disclaimerButton.preventDefault();

        action = jQuery(this).data('disclaimer');

        if( action === 'accept' ) {
            setCookie('disclaimer_state', 'active', 356);
        } else {
            setCookie('disclaimer_state', 'error', 356);
        }

        location.reload();

    });
});