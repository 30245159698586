// ebanking button handling - from pre-existing website

(function ($) {

	function setInterestCardHeight() {
		var maxCardHeight = 0;
		$('.card-interest .tabs-content > .tabs-panel').each(function () {

			// We have to make the panel visible before we can measure it
			$(this).css({
				'visibility': 'hidden',
				'display': 'block'
			});

			var cardHeight = $(this).outerHeight(true);

			// Removes the inline values we've just set and 
			// returns control to the CSS file definitions
			$(this).css({
				'display': '',
				'visibility': ''
			});

			if (maxCardHeight < cardHeight) {
				maxCardHeight = cardHeight;
			}
		});
		// $('.card-interest .tabs-content').height(maxCardHeight);
	}

	$(window).on('load resize', setInterestCardHeight);

})(jQuery);
