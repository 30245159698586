(function ($) {

    $( '.ct-appstore-sms' ).submit(function( event ) {
        $form = $(this);
        $form.addClass('pending');

        let baseUrl = $form.attr('data-endpoint');
        jQuery.ajax( {
            url: baseUrl,
            dataType: 'json',
            method: 'POST',
            data: $form.serialize()
        } ).done( function ( data ) {
            if (typeof data !== 'undefined') {
                $form.removeClass('pending');
                $form.find('.form-content').hide();
                if (data) {
                    $form.find('.is-valid').show();
                }
                else {
                    $form.find('.is-invalid').show();
                }
            }
        } );
        event.preventDefault();
    });

    $( '.ct-appstore-sms .retry' ).click(function( event ) {
        $form = $(this).closest('.ct-appstore-sms');
        $form.find('.is-valid').hide();
        $form.find('.is-invalid').hide();
        $form.find('.form-content').show();
        event.preventDefault();
    });

}(jQuery));