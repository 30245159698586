(function ($) {

	$(document).on('ready.backtotop', function () {
		if ($('[data-pagetop-anchor]').length === 1) {
			$('[data-pagetop-link]').on('click.backtotop', function (event) {
				event.preventDefault();
				$("html, body").animate({
					scrollTop: $('[data-pagetop-anchor]').offset().top
				}, 200, 'swing');
			});
		}
	});

}(jQuery));
