(function ($) {
	$('[data-load-more-images]').on('click', function () {
		var $moreButton = $(this);
		var page = $moreButton.attr('data-load-more-images');
		if (!isNaN(page)) {
			page++;
			$moreButton.attr('data-load-more-images', false);
			var $gallery = $(this).parents('[data-gallery]');
			$.post(projectsAjax.url,
				{
					'action': 'ct_load_image',
					'index': $moreButton.attr('data-index'),
					'page': page,
					'id': $moreButton.attr('data-id'),
				}
			).done(function (result) {
				$gallery.find('[data-content]').append(result.data.html);
				if (!result.data.haveMore) {
					$moreButton.prop('disabled', true);
				}
				$moreButton.attr('data-load-more-images', page);
			}).fail(function () {
				console.error("Failed to load more images");
			});
		}
	});
})(jQuery);