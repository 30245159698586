/**
 * File containing code which reacts to general events
 * @since 16.6.2017 mhm
 */

var Foundation;

(function($){

    // Reset any equalized elements if a sticky element is stuck or unstuck
    // Makes sure that level2 menu elements' height is corrected
    // $(window).on('sticky.zf.unstuckfrom:top sticky.zf.unstuckfrom:bottom sticky.zf.stuckto:top sticky.zf.stuckto:bottom', function(){
    //     if(Foundation && Foundation.reInit){
    //     	try {
    //        		Foundation.reInit('equalizer');
    //         } catch(e) {
    //         	// fail silently please
    //         }
    //     }
    // });

    /**
     * Manually set a full-height element, because the window height adjusts itself
     * in mobile because of the varying height/apearance of the browser's own toolbar
     */
    $(window).on('load resize sticky.zf.unstuckfrom:top sticky.zf.unstuckfrom:bottom sticky.zf.stuckto:top sticky.zf.stuckto:bottom', function(){
        if('innerHeight' in window){
            $('[data-ct-fullheight]').css({
                'min-height': window.innerHeight + 'px'
            });
            // if(window.console){
            //     window.console.info('ct-fullheight: '+ window.innerHeight + 'px');
            // }
        }
    });

}(jQuery));