(function ($) {
    function adjustSearchTop() {
        $('.search-overlay').css({ 'top': 0 });

        // mobile scrolltop
        $('.position-right').scrollTop(0);

        // if ($('.search-overlay').length) {
        // 	var headerHeight = $('.header-bar .top-bar-wrap').outerHeight(); // - $('.page-section-submenu-level2').outerHeight();
        // 	$('.search-overlay').css({ 'top': headerHeight });
        // }
    }

    $('.search-open').on('click', function () {
        adjustSearchTop();
        $(window).trigger('wpplugin.ajax-search/search-open');
        setTimeout(function () {
            $('.top-bar .ajax_search_form .search-field').focus();
        }, 350);
    });

    $('body').on('click', '[data-search-preview]', function(){
        $('.search-field').val($(this).text());
        $('.search-field').focus();
    });

    $(document).ready(function(){
        let overlay = $('.search-overlay').clone();
        $('body').prepend(overlay);
    });

    /*var loading = false;
    var resultHtmlContainer = '<div class="results--area results--other"><div class="results--ct_elasticsearch"><h3 class="results--title">Suchergebnisse</h3>';
    var resultHtmlContainerClose = '</div></div>';
    var readMore = 'Weiterlesen';

    if (__wp_lang !== undefined && __wp_lang !== 'de') {
        readMore = 'More';
    }


    $(window).on('load.dm', function () {
        if ($('.search-overlay').length && $('[data-search-overlay-anchor]').length) {
            $('.search-overlay').detach().prependTo('[data-search-overlay-anchor]');
        }
    });

    function adjustSearchTop() {
        $('.search-overlay').css({ 'top': 0 });

        // mobile scrolltop
        $('.position-right').scrollTop(0);

        // if ($('.search-overlay').length) {
        // 	var headerHeight = $('.header-bar .top-bar-wrap').outerHeight(); // - $('.page-section-submenu-level2').outerHeight();
        // 	$('.search-overlay').css({ 'top': headerHeight });
        // }
    }

    $(window).on('load.dm resize.dm', adjustSearchTop);

    $('.search-open').on('click', function () {
        adjustSearchTop();
        $(window).trigger('wpplugin.ajax-search/search-open');
        setTimeout(function () {
            $('.top-bar .ajax_search_form .ajax_search').focus();
        }, 350);
    });

    function updateResultCount(count) {

        if (count > 1) {
            $('.results-count .count').text(count);
            $('.results-count .results-label').text($('.results-count .results-label').attr('data-label-multiple'));
        } else if (count === 0) {
            $('.results-count .results-label').text('');
            $('.results-count .results').text('');
            $('.results-count .count').text('');
        } else {
            $('.results-count .count').text(count);
            $('.results-count .results-label').text($('.results-count .results-label').attr('data-label-single'));
        }
    }

    function onSearchSuccess(result) {
        $('.loader').css('opacity', 0);
        if (result.data.length > 0) {
            $('.results--area').append(resultHtmlContainer);
            result.data.forEach(function (result) {
                var post_title = ((typeof result.post_title === 'undefined') ? '' : result.post_title);
                var excerpt = ((typeof result.excerpt === 'undefined') ? '' : result.excerpt);
                var guid = ((typeof result.guid === 'undefined') ? '' : result.guid);
                var resultHtml = '<div class="row result--single"><div class="column small-8"><h4>' + post_title + '</h4> <p>' + excerpt + '</p></div><div class="column small-4 text-right"><a class="read-more" href="' + guid + '" title="' + post_title + '"> ' + readMore + '</a></div></div>';
                $('.results--ct_elasticsearch').append(resultHtml);
            });
            $('.results--area').append(resultHtmlContainerClose);
        }

        updateResultCount(result.data.length);
        loading = false;
    }

    function onSearchError(error) {
        loading = false;
        if (error.hasOwnProperty('responseText')) {
            responseText = JSON.parse(error.responseText);
        }
        if (responseText.data.code === 'rest_search_no_results') {
            $('.loader').css('opacity', 0);
            $('.no-results-info').fadeIn("slow");
        } else {
            console.log(error);
        }
    }


    function doSearch(query) {
        var restBase = document.location.protocol + '//' + document.location.hostname + '/wp-json/cubetech/';
        $.ajax({
            success: onSearchSuccess,
            error: onSearchError,
            url: restBase + 'ajaxSearch/default/' + query,
            dataType: 'json',
            method: 'GET',
        });
    }

    $('.ajax_search').on('keyup', function (e) {
        if ($('.results--area').length > 1) {
            $('.results--area').last().remove();
            updateResultCount(0);
        }
        $('.no-results-info').hide();
        $('.loader').css('opacity', 1);
        var value = $(this).val();
        if (value.length > 3 && !loading) {
            loading = true;
            doSearch(value);
        }
    });*/

})(jQuery);
