// ebanking button handling - from pre-existing website

(function($){

    function checkiOS() {
        return navigator.userAgent.match(/(iPod|iPhone|iPad)/) && navigator.userAgent.match(/AppleWebKit/);
    }

    function checkAndroid() {
        return navigator.userAgent.match(/(Android)/);
    }

    var ebankingLink = $('.e-banking-link');
    var href = ebankingLink.attr('href');

    var customURL = 'spi-SLM-mbanking://'; //ebankingLink.data('custom-url');
    var iOSLink = 'https://itunes.apple.com/ch/app/slm-mobile-banking/id986110814?l=de'; // ebankingLink.data('ios-link');
    var androidLink = 'https://play.google.com/store/apps/details?id=com.slm.android.mbanking'; //ebankingLink.data('android-link');

    var target = ebankingLink.attr('target');
    target = void 0 !== target ? target : '_self';

    ebankingLink.on('click.handleAppRequest', function(ebankingLink) {
        if (ebankingLink.preventDefault(), checkiOS()) {
            var methods = {
                launchApp: function() {
                    window.location.replace(customURL);
                    this.timer = setTimeout(this.openWebApp, 1e3);
                },
                openWebApp: function() {
                    window.location.replace(iOSLink);
                }
            };
            methods.launchApp();
        } else {
            if (checkAndroid()) {
                window.location = androidLink;
            } else {
                window.open(href, target);
            }
        }
    });

})(jQuery);
