var Foundation;

(function($) {

    // Variables which are scoped to this file
    var $rootElement, selectedProductID = null;

    /**
     * Show the matching entries in the 'additional_products' list, according to the selected productID
     */
    function displayAdditionalProducts() {

        var visibleAdditionalProducts = 0;

        $('.additional_products ul > li > input').each(function() {
            if (parseInt(selectedProductID) !== parseInt($(this).val().split(',')[0])) {
                $(this).parent().addClass('hidden-additional-product');
            } else {
                var defaultAddProdID = parseInt($(this).val().split(',')[1]);
                //  Hardcode Product-ID for E-Banking default Select AdditionalProduct
                if( defaultAddProdID === 2516){
                    $(this).attr('checked','checked');
                }
                //$(this).parent().removeClass('hidden-additional-product');
                visibleAdditionalProducts++;
            }
        });

        // Debugging
        // if(!visibleAdditionalProducts && window.console){
        //     window.console.info('No additional products available for product ID '+selectedProductID);
        // }

        if (visibleAdditionalProducts !== 0) {
            $('.additional_products_wrapper').removeClass('gfield_visibility_hidden').addClass('gfield_visibility_visible');
            $('.gform_page:first-child .gform_page_footer', $rootElement).removeClass('no-additional-products');
        } else {
            $('.additional_products_wrapper').addClass('gfield_visibility_hidden').removeClass('gfield_visibility_visible');
            $('.gform_page:first-child .gform_page_footer', $rootElement).addClass('no-additional-products');
        }

        $('.entry-wrapper').each(function () {
            if ($(this).hasClass('is-selected')) {
                $(this).find('.product-description').removeClass('hidden-product-description');
                $(this).find('.product-description').addClass('show-product-description');
                $(this).find('.link-to-show-more').addClass('hidden-is-not-selected');
            } else {
                $(this).find('.product-description').addClass('hidden-product-description');
                $(this).find('.product-description').removeClass('show-product-description');
                $(this).find('.link-to-show-more').removeClass('hidden-is-not-selected');
            }
        });
    }

    function initializeView() {

        $rootElement = $('.ct-customfield[data-product]');

        /**
         * Event handler, which is fired to switch the product list state from open to closed
         */
        $rootElement.on('show_more_products::closepopup', function() {
            var $wrapper = $(this).closest('.ct-customfield'),
                $popup = $(this).closest('.ct-customfield-productlist');

            $wrapper.addClass('is-closed').removeClass('is-open');
            displayAdditionalProducts();
        });

        $rootElement.on('show_more_products::equalize', function() {
            if (Foundation && Foundation.reInit) {
                try {
                    Foundation.reInit('equalizer');
                } catch (e) {
                    // fail silently please
                }
            }
        });
    }

    /**
     * Get the selectedProductID from the selected checkbox in the .final-selected-product')find('.product-popup-list
     * The variable selectedProductID is set within the overall scope of this file, not
     * in the function.
     */
    function setSelectedProductId() {
        if ($('.final-selected-product').find('.product-popup-list input:checked').length) {
            if (typeof($('.product-popup-list input:checked').val()) === 'string') {
                selectedProductID = $('.final-selected-product').find('.product-popup-list input:checked').val().split(',')[1];
            }
        }
        displayAdditionalProducts();
    }

    /**
     * Add the event handlers to open and close the panel
     * @return {[type]} [description]
     */
    function initializePanel() {

        $('[data-close-panel]').on('click', function() {
            $rootElement.trigger('show_more_products::closepopup');
        });

        $('[data-open-panel]').on('click', function() {

            var $wrapper = $(this).closest('.ct-customfield'),
                $popup = $(this).closest('.ct-customfield-productlist');

            $wrapper.removeClass('is-closed').addClass('is-open');

            $rootElement.trigger('show_more_products::equalize');

            displayAdditionalProducts();
        });
    }

    function bindProductOrderHandling() {

        setSelectedProductId();
        displayAdditionalProducts();

        $('.gchoice-wrapper input[type="radio"]').on('change.gchoice', function() {

            var $rootElement = $(this).closest('.ct-customfield');

            $('.entry-wrapper', $rootElement).removeClass('is-selected');
            $(this).closest('.entry-wrapper').addClass('is-selected');

            $(this).prop('checked', true);
            if ($(this).is(':checked')) {
                $(this).closest('.entry-wrapper').addClass('is-selected');
            }

            setSelectedProductId();
            displayAdditionalProducts();

            $rootElement.trigger('show_more_products::closepopup');

        });

        $('.gchoice-choose').on('click', function(e) {
            e.preventDefault();
            $(this).closest('.gchoice-wrapper').find('input').trigger('click');
        });

        $('.gchoice-label').on('click', function(e) {
            e.preventDefault();
        });
    }

    // Event to fire when switching between pages in multipage form
    $(document).on('gform_page_loaded', function(event, form_id, current_page) {

        initializeView();
        $rootElement.foundation(); // need to reinitialize because the view has been rebuilt via AJAX

        $('.gform_previous_link').remove();

        if (parseInt(current_page) > 1) {

            var backText;

            if ($('.gform_previous_button').length) {
                backText = $('.gform_previous_button')[0].value;
            } else {
                backText = 'Schritt zurück';
            }

            $('.gf_page_steps').after('<a class="gform_previous_link">' + backText + '</a>');

            $('.gform_previous_link').on('click', function(e) {
                e.preventDefault();
                $('.gform_previous_button').trigger('click');
            });
        }

        bindProductOrderHandling();
        initializePanel();
        displayAdditionalProducts();
    });

    $(document).ready(function() {

        var indexPosition = 0;
        var initialLoad = true;
        var counter = 0;

        initializeView();

        $('.additional_products_wrapper .gfield_label').text('Zu Ihrer Wahl empfehlen wir');

        // more information hover class handling
        $('.gchoice-more a').hover(function() {
            $(this).parent().parent().addClass('gchoice-more-hovered');
        }, function() {
            $(this).parent().parent().removeClass('gchoice-more-hovered');
        });

        $('.final-selected-product').find('.product-popup-list > div > label').on('click', function() {
            var clickedValue = $(this).prev().val().split(',')[1];

            $('.additional_products div input').each(function() {
                if (clickedValue !== $(this).val().split(',')[0]) {
                    $(this).parent().addClass('hidden-additional-product');
                }
            });

        });

        bindProductOrderHandling();
        initializePanel();
    });

    $(window).on('load resize orientationchange', function(event) {
        if ($rootElement) {
            $rootElement.trigger('show_more_products::equalize');
        }
    });

})(jQuery);
